import { Component, inject, signal, OnInit, computed } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { UserService } from '../services/user.service';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    RouterModule
  ],
  template: `
    <nav class="navbar">
      <button mat-icon-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      
      <span class="app-title">{{ title() }}</span>
      <div class="buttonArea">
        @if(userService.isLoggedIn()) {
          <button mat-button [routerLink]="['/settings/profile']" class="profile-button">
            <div class="avatar-circle">
              {{ getUserInitials() }}
            </div>
          </button>
        } @else {
          <button mat-button [routerLink]="getAuthButtonLink()" class="auth-button">
            <span>{{ getAuthButtonText() }}</span>
          </button>
        }
      </div>
    </nav>
  `,
  styles: [`
    .navbar {
      display: flex;
      align-items: center;
      background-color: #D4E1E6;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      padding: 0.5rem;
    }

    .app-title {
      flex: 1;
      text-align: center;
      font-size: 1.2rem;
    }

    .buttonArea {
      display: flex;
      align-items: center;
    }

    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
      margin-right: 1rem;

      span {
        font-size: 1rem;
        line-height: 1;
      }
    }

    .auth-button {
      min-width: 100px;
    }

    .profile-button {
      min-width: 40px;
      padding: 0.25rem;
    }

    .avatar-circle {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--theme-accent);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      user-select: none;
    }
  `]
})
export class NavbarComponent implements OnInit {
  userService = inject(UserService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);

  title = signal<string>('AWO Care');
  currentRoute = signal<string>(this.router.url);

  getUserInitials(): string {
    const email = this.userService.currentUser()?.email;
    if (!email) return '??';

    // Get first part of email (before @)
    const localPart = email.split('@')[0];

    // If email starts with a number, return first two characters
    if (/^\d/.test(localPart)) {
      return localPart.slice(0, 2);
    }

    // Otherwise, get up to first two letters, ignoring numbers
    const letters = localPart.replace(/[^a-zA-Z]/g, '');
    return letters.slice(0, 2).toUpperCase();
  }

  constructor() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute;
        while (route.firstChild) {
          route = route.firstChild;
        }
        return {
          url: this.router.url,
          title: route.snapshot.data['title']
        };
      })
    ).subscribe(({ url, title }) => {
      this.currentRoute.set(url);
      if (title) {
        this.title.set(title);
      } else {
        this.title.set('AWO Care');
      }
    });
  }

  ngOnInit(): void {
    this.currentRoute.set(this.router.url);
  }

  getAuthButtonText(): string {
    const currentUrl = this.currentRoute();
    switch (currentUrl) {
      case '/auth/login':
        return 'Registrieren';
      case '/auth/register':
        return 'Login';
      default:
        return 'Login';
    }
  }

  getAuthButtonLink(): string[] {
    const currentUrl = this.currentRoute();
    switch (currentUrl) {
      case '/auth/login':
        return ['/auth/register'];
      case '/auth/register':
        return ['/auth/login'];
      default:
        return ['/auth/login'];
    }
  }

  goBack(): void {
    window.history.back();
  }
}