import { Route } from '@angular/router';
import { ClaimGuard } from './core/guards/claim.guard.ts';

export const appRoutes: Route[] = [
    {
        path: "",
        loadChildren: () => import('./home/home.routes').then(mod => mod.HOME_ROUTES)
    },
    {
        path: "auth",
        loadChildren: () => import('./auth/auth.routes').then(mod => mod.AUTH_ROUTES)
    },

    {
        path: "registration",
        loadChildren: () => import('./registration/registration.routes').then(mod => mod.REGISTRATIONS_ROUTES)
    },
    {
        path: "settings",
        loadChildren: () => import('./settings/settings.routes').then(mod => mod.SETTINGS_ROUTES),
        canActivate: [ClaimGuard],
        data: { requiredClaims: ['user'] },
    },
    {
        path: "**",
        redirectTo: ""
    }
];
