import { ApplicationConfig, isDevMode, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { connectFunctionsEmulator, getFunctions, provideFunctions } from '@angular/fire/functions';
import { provideServiceWorker } from '@angular/service-worker';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '@awo-care/shared-auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

export const appConfig: ApplicationConfig = {
  providers: [

    provideRouter(appRoutes, withComponentInputBinding(),),
    { provide: LOCALE_ID, useValue: 'de-DE' },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    provideAnimationsAsync(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),

    provideAuth(() => {
      const auth = getAuth();
      // if (environment.useEmulators) {
      //   connectAuthEmulator(auth, "http://localhost:9099");
      // }
      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      // if (environment.useEmulators) {
      //   connectFirestoreEmulator(firestore, "localhost", 8080);
      // }
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      functions.region = "europe-west3";
      if (environment.useEmulators) {
        console.log("Connecting to emulator");
        connectFunctionsEmulator(functions, "localhost", 5001);
      }
      return functions;
    }),
  ],
};

