import { Component, inject } from '@angular/core';
import { ThemeService } from '@awo-care/utils';
import { NavbarComponent } from "./core/components/navbar.component";
import { environment } from '@awo-care/shared-auth';
import { RouterOutlet } from '@angular/router';
import { UserService } from './core/services/user.service';
import { LanguageService } from './core/services/language.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [NavbarComponent, RouterOutlet],
  template: `
    @if(userService.isInitialized()) {
    <app-navbar />
    <router-outlet/>
    }
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private themeService = inject(ThemeService);
  userService = inject(UserService);
  languageService = inject(LanguageService);

  constructor() {
    console.log();
    this.themeService.initializeTheme('brand-theme');

    if (!environment.production) {
      const splashScreen = document.getElementById('splash-screen');
      splashScreen?.remove();
    }
  }

  ngOnInit() {
    setTimeout(() => {
      const splashScreen = document.getElementById('splash-screen');
      if (splashScreen) {
        splashScreen.classList.add('hide');
        setTimeout(() => splashScreen.remove(), 500);
      }
    }, 500);

  }
}