import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../services/user.service";

@Injectable({
    providedIn: "root"
})
export class ClaimGuard {
    private router = inject(Router);
    private userService = inject(UserService);

    async canActivate(): Promise<boolean> {
        if (!this.userService.isInitialized()) {
            await this.userService.loadClaims();
        }

        const user = this.userService.currentUser();
        //ToDo: Check for emailVerified
        // if (!user || !user.emailVerified) {
        if (!user || !user.uid || !user.emailVerified) {
            this.router.navigate(["auth/login"]);
            return false;
        }

        // const requiredClaims = next.data['requiredClaims'] as string[] | undefined;
        // if (!requiredClaims || requiredClaims.length === 0) {
        //     return true;
        // }

        // const hasAnyRequiredClaim = requiredClaims.some(claim => this.userService.hasClaim(claim));
        // if (!hasAnyRequiredClaim) {
        //     this.router.navigateByUrl("/auth/login");
        //     return false;
        // }

        return true;
    }
}